<template>
  <div v-if="respData && $ability.can('read', this.$route.meta.resource)">
    <b-row
      class="match-height"
    >
      <b-col md="6">
        <b-card title="Store">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Grocery :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['grocery']['status'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.grocery.status', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['grocery']['status'] == true
                    ? "เปิดระบบร้านของชำ"
                    : "ปิดระบบร้านของชำ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>

          </b-row>
          <b-row
            v-if="respData['system']['store']['grocery']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              Called :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['grocery']['config']['called'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.grocery.config.called', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['grocery']['config']['called'] == true
                    ? "เปิดระบบร้านของชำ (User รับที่บ้าน)"
                    : "ปิดระบบร้านของชำ (User รับที่บ้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['store']['grocery']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              goFind :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['grocery']['config']['goFind'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.grocery.config.goFind', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['grocery']['config']['goFind'] == true
                    ? "เปิดระบบร้านของชำ (User ไปรับที่ร้าน)"
                    : "ปิดระบบร้านของชำ (User ไปรับที่ร้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Store">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Food :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['food']['status'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.food.status', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['food']['status']== true
                    ? "เปิดระบบร้านขายอาหาร"
                    : "ปิดระบบร้านขายอาหาร"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['store']['food']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              Called :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['food']['config']['called'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.food.config.called', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['food']['config']['called'] == true
                    ? "เปิดระบบร้านของชำ (User รับที่บ้าน)"
                    : "ปิดระบบร้านของชำ (User รับที่บ้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['store']['food']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              goFind :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['food']['config']['goFind'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.food.config.goFind', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['food']['config']['goFind'] == true
                    ? "เปิดระบบร้านของชำ (User ไปรับที่ร้าน)"
                    : "ปิดระบบร้านของชำ (User ไปรับที่ร้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Store">
          <b-row class="mt-1">
            <b-col cols="6">
              <b-row>
                <b-card-text class="ml-2 mr-2">
                  Queue :
                </b-card-text>
                <b-form-checkbox
                  :checked="
                    respData['system'] != null ? respData['system']['store']['queue']['status'] : false
                  "
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  @change="switchChange('system.store.queue.status', $event)"
                />
                <b-card-text class="ml-2">
                  {{
                    respData["system"] != null
                      ? respData['system']['store']['queue']['status'] == true
                        ? "เปิดระบบร้านจองคิว"
                        : "ปิดระบบร้านจองคิว"
                      : "ยังไม่ได้ตั้งค่า"
                  }}
                </b-card-text>
              </b-row>

            </b-col>
            <b-col
              v-if="(respData['system'] != null && respData['system']['store']['queue']['status']) === true"
              cols="6"
            >
              <b-row>
                <b-card-text class="ml-1 mr-1">
                  Show :
                </b-card-text>
                <b-form-checkbox
                  :checked="
                    respData['system'] != null ? respData['system']['store']['queue']['show'] : false
                  "
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  @change="switchChange('system.store.queue.show', $event)"
                />
                <b-card-text>
                  {{
                    respData["system"] != null
                      ? respData['system']['store']['queue']['show'] == true
                        ? "แสดงระบบร้านจองคิว"
                        : "ปิดการแสดงระบบร้านจองคิว"
                      : "ยังไม่ได้ตั้งค่า"
                  }}
                </b-card-text>
              </b-row>
            </b-col>
          </b-row>

          <b-row
            v-if="respData['system']['store']['queue']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              Called :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['queue']['config']['called'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.queue.config.called', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['queue']['config']['called'] == true
                    ? "เปิดระบบร้านของชำ (User รับที่บ้าน)"
                    : "ปิดระบบร้านของชำ (User รับที่บ้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['store']['queue']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              goFind :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['store']['queue']['config']['goFind'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.store.queue.config.goFind', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['store']['queue']['config']['goFind'] == true
                    ? "เปิดระบบร้านของชำ (User ไปรับที่ร้าน)"
                    : "ปิดระบบร้านของชำ (User ไปรับที่ร้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>

        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Driver">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Delivery :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['driver']['delivery']['status'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.driver.delivery.status', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['driver']['delivery']['status'] == true
                    ? "เปิดระบบคนขับจัดส่ง"
                    : "ปิดระบบคนขับจัดส่ง"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['driver']['delivery']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              Called :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['driver']['delivery']['config']['called'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.driver.delivery.config.called', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['driver']['delivery']['config']['called'] == true
                    ? "เปิดระบบคนขับจัดส่ง (User รับที่บ้าน)"
                    : "ปิดระบบคนขับจัดส่ง (User รับที่บ้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['driver']['delivery']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              goFind :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['driver']['delivery']['config']['goFind'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.driver.delivery.config.goFind', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['driver']['delivery']['config']['goFind'] == true
                    ? "เปิดระบบคนขับจัดส่ง (User ไปรับที่ร้าน)"
                    : "ปิดระบบคนขับจัดส่ง (User ไปรับที่ร้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Driver">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Call :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['driver']['call']['status'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.driver.call.status', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['driver']['call']['status']== true
                    ? "เปิดระบบเรียกรถ"
                    : "ปิดระบบเรียกรถ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['driver']['call']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              Called :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['driver']['call']['config']['called'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.driver.call.config.called', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['driver']['call']['config']['called'] == true
                    ? "เปิดระบบเรียกรถ (User เรียกมารับอยู่บ้าน)"
                    : "ปิดระบบเรียกรถ (User เรียกมารับอยู่บ้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['driver']['call']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              goFind :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['driver']['call']['config']['goFind'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.driver.call.config.goFind', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['driver']['call']['config']['goFind'] == true
                    ? "เปิดระบบเรียกรถ (User ไปหาแล้วค่อยใช้บริการ)"
                    : "ปิดระบบเรียกรถ (User ไปหาแล้วค่อยใช้บริการ)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Services">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Services :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['services']['status'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.services.status', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['services']['status'] == true
                    ? "เปิดระบบบริการต่างๆ"
                    : "ปิดระบบบริการต่างๆ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['services']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              Called :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['services']['config']['called'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.services.config.called', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['services']['config']['called'] == true
                    ? "เปิดระบบบริการต่างๆ (User เรียกมาที่บ้าน)"
                    : "ปิดระบบบริการต่างๆ(User เรียกมาที่บ้าน)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row
            v-if="respData['system']['services']['status']"
            class="mt-1"
          >
            <b-card-text class="ml-5 mr-2">
              goFind :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['services']['config']['goFind'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.services.config.goFind', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['services']['config']['goFind'] == true
                    ? "เปิดระบบบริการต่างๆ (User ไปหาข้างนอก)"
                    : "ปิดระบบบริการต่างๆ (User ไปหาข้างนอก)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Live Streaming">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Live Streaming :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['liveStreaming']['status'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.liveStreaming.status', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData['system']['liveStreaming']['status'] == true
                    ? "เปิดระบบถ่ายทอดสด"
                    : "ปิดระบบถ่ายทอดสด"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  // BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  // BFormGroup,
  // BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/services/hero/system'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'setupSystem'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    // BFormGroup,
    // BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Color
      // currentVersion: '0.0.1',
      // otpSetting
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    currentVersion: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.currentVersion
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'currentVersion',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {
        console.log('fetch Success : ', result)
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    inputChange(name) {
      const obj = {
        currentVersion: store.state[STORE_MODULE_NAME].respData.currentVersion,
      }
      if (name === 'currentVersion') {
        obj.currentVersion = this.currentVersion
      }
      console.log(name)

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      console.log(name, status)
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
